import React, { useEffect, useState } from 'react';
import { usePasswordless } from "amazon-cognito-passwordless-auth/react";
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router';


const ClientRedirectionHandler: React.FC = () => {
  const {
    signInStatus,
    tokens,
    tokensParsed,
  } = usePasswordless();
  var successRedirectionUrl = "";
  const [apiResponse, setApiResponse] = useState(null);
  var [clientDetails, setClientDetails] = useState<any>(null);
  var [randomProductDetails, setRandomProductDetails] = useState<any>(null);
  interface ProductDetails {
    mainImage: string;
    mainLogo: string;
    mainHeading: string;
    mainSubText: string;
    backGroundImage: string;
    mainBTN: string;
    mainBtnLink: string;
  }
  const location = useLocation();
  const productDetailsMap: { [key: string]: ProductDetails } = {
    Deployer: {
      mainImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5cd9/aef8/6970/2d76/2327/7300/original_deployer-duo.png?1557769976',
      mainLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5cd9/ab99/6970/2d5a/64d0/6600/original_deployer.png?1557769113',
      mainHeading: 'Inboxing is a super power.',
      mainSubText: 'Welcome to the League of Extraordinary Marketers.',
      backGroundImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/themes/images/globe.svg?1559573349',
      mainBtnLink: 'https://marketrithm.com/deployer',
      mainBTN: 'START NOW'
  },
  StructureCMS: {
    mainImage:'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5d16/1f8c/6970/2d6a/b353/0c00/original_structure.screen.edit.png?1561730955',
    mainLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5cd9/9708/6970/2d79/fa2c/4800/original_structurecms.logo.png?1557763848',
    mainHeading: 'Your website workflow reimagined.',
    mainSubText: 'Structure CMS provides enterprise level power for users with entry level skills. Our best of breed CMS has a simple, highly intuitive suite of tools that gives you complete command of your web properties. ',
    backGroundImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/themes/images/pattern.svg?1559573375',
    mainBtnLink: 'https://marketrithm.com/structure-cms',
    mainBTN: 'START NOW'
  },
  EmailDesigner: {
      mainImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/6238/ffcb/ee1f/9237/21d7/6406/original_email-designer.jpg?1647902667',
      mainLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/645b/b8b6/2c27/f96c/1fb3/4e2a/original_0-02-01-68349db4d38993a2541ebfaf04b8386600291100a6487c0f993d1ae0c7f0105d_1c6db05e2a0127.png?1683732662',
      mainHeading: 'Level up your email messaging.',
      mainSubText: 'Effective messaging starts with effective presentation. Email Designer™ brings your vision and brand to life with custom template design, user-friendly editing, and seamless imagery and media integration. Create professional emails that reflect your personal brand, impress your audience, and get results.',
      backGroundImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/themes/images/email-designer-e.svg?1647895387',
      mainBtnLink: 'https://marketrithm.com/email-designer',
      mainBTN: 'START NOW'
  },
  ValidatePlus: {
      mainImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5d00/058b/6970/2d72/f1ae/2900/original_validate-profile.jpg?1560282507',
      mainLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5cd9/af3e/6970/2d79/f0ba/5a00/original_validateplus.png?1557770046',
      mainHeading: 'Flipping email validation on its head. ',
      mainSubText: 'Validate Plus(R) is the only multi-source email hygiene aggregator that delivers a near-perfect email list. We flag traps, score engagement, and enhance data to drive top deliverability rates that help you convert sales and increase subscriptions.Our comprehensive range of validation services and PLUS features enhance your reputation, lower your mailing costs, identify quality leads, deliver accurate statistics, and help you personalize your emails. ',
      backGroundImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/themes/images/finger-print.svg?1559573349',
      mainBtnLink: 'https://marketrithm.com/validate-plus',
      mainBTN: 'START NOW'
  },
  RithmCrm: {
      mainImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5dd2/fc81/8e3b/a636/8b57/8e38/content_rithmcrm-hero-v2.jpg?1574112659',
      mainLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5dd2/f36b/8e3b/a636/7257/94ad/original_rithmcrm.png?1574105963',
      mainHeading: 'The Rithm of Relationships',
      mainSubText: 'Rithm CRM provides a platform to create your own intelligent lifecycle algorithms, execute your business logic and automations without programmers. ',
      backGroundImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/themes/images/refine-data.svg?198697659541',
      mainBtnLink: 'https://marketrithm.com/rithm-crm',
      mainBTN: 'START NOW'
  },
  CopyApproval: {
      mainImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/6238/ebed/a386/a037/31c2/083c/original_copy-approve-top.jpg?1647897581',
      mainLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/6238/d698/97d1/1137/0f5a/f4cb/original_copyapproval.logo.svg?1647892116',
      mainHeading: 'Simplify your approval experience.',
      mainSubText: 'Would not it be great if you could generate copy, customize templates, request edits, and approve the final product all from one place? Good news — that’s exactly what Copy Approval is designed to do. Time to cut through the unnecessary roadblocks and give your message the expedient deployment it deserves. ',
      backGroundImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/themes/images/copy-approvalb-e.svg?1647890804',
      mainBtnLink: 'https://marketrithm.com/copy-approval',
      mainBTN: 'START NOW'
  },
  Multiplizer: {
      mainImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/63cf/ec18/dfe0/770e/532e/3fb9/original_original_mulitplizer-top.jpg?1674570775',
      mainLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/63d0/25ce/f57c/cd0d/8710/4e3e/original_multiplizer.logo.svg?1674585549',
      mainHeading: 'The ultimate solution to your monetization needs.',
      mainSubText: 'Think monetizing your website will be a hassle? Complicated? Too expensive? Think again. With Multiplizer, getting high-quality, profitable ads placed on your site couldn’t be easier. ',
      backGroundImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/themes/assets/multiplizer-e-back-01.svg?1674577344',
      mainBtnLink: 'https://marketrithm.com/multiplizer',
      mainBTN: 'START NOW'
  },
  IgniteCdn: {
      mainImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/634e/b062/90b4/ce06/c7ed/1532/original_original_ingnite-top.jpg?1666101346',
      mainLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/634e/af0f/fdb9/8e0d/1380/d298/original_0-02-01-75dc2b68f308806094c42bd0d1e7b22ae061312ceda557e785c41c17d025a6c7_1c6dace3905bfd.png?1666101006',
      mainHeading: 'Designed for real-world performance at blazing speeds',
      mainSubText: 'Ignite CDN is a high performance content delivery network that solves your most challenging delivery obstacles. Your content, your way. We apply modern format compressions so that you can be one step closer to end-users and devices. Never make your audience wait again. ',
      backGroundImage: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/themes/images/cdn-e-01.svg?1666209810',
      mainBtnLink: 'https://marketrithm.com/ignite-cdn',
      mainBTN: 'START NOW'
  }
  };

  useEffect(()=> {
    const getQueryParam = (param: string) => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return urlParams.get(param);
    };

    document.body.style.overflow = 'hidden';

    const clientDetailsMap: { [key: string]: { cardLogo: string; cardName: string;} } = {
      AccountConsole: { cardLogo: 'https://mr.cdn.ignitecdn.com/client_assets/acccount_structure/media/themes/img/accountconsole_logo2.svg', cardName: 'AccountConsole'},
      StructureCMS: { cardLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5cd9/9708/6970/2d79/fa2c/4800/original_structurecms.logo.png?1557763848', cardName: 'StructureCMS' },
      Deployer: {cardLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5cd9/ab99/6970/2d5a/64d0/6600/original_deployer.png?1557769113', cardName: 'Deployer'},
      EmailDesigner: {cardLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/645b/b8b6/2c27/f96c/1fb3/4e2a/original_0-02-01-68349db4d38993a2541ebfaf04b8386600291100a6487c0f993d1ae0c7f0105d_1c6db05e2a0127.png?1683732662', cardName: 'EmailDesigner'},
      ValidatePlus: {cardLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5cd9/af3e/6970/2d79/f0ba/5a00/original_validateplus.png?1557770046', cardName: 'ValidatePlus'},
      RithmCrm: {cardLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5dd2/f36b/8e3b/a636/7257/94ad/original_rithmcrm.png?1574105963', cardName: 'RithmCrm'},
      CopyApproval: {cardLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/6238/d698/97d1/1137/0f5a/f4cb/original_copyapproval.logo.svg?1647892116', cardName: 'CopyApproval'},
      Multiplizer: {cardLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/63d0/25ce/f57c/cd0d/8710/4e3e/original_multiplizer.logo.svg?1674585549', cardName: 'Multiplizer'},
      IgniteCdn: {cardLogo: 'https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/634e/af0f/fdb9/8e0d/1380/d298/original_0-02-01-75dc2b68f308806094c42bd0d1e7b22ae061312ceda557e785c41c17d025a6c7_1c6dace3905bfd.png?1666101006', cardName: 'IgniteCdn'}
    };

    var clientName = getQueryParam('clientName');
    if (clientName != null){
      if(clientName == 'StructureCMSLms'){
        setClientDetails(clientDetailsMap['StructureCMS']);
      }
      else{
        setClientDetails(clientDetailsMap[clientName]);
      }
      if(clientName == 'StructureCMS' || clientName == 'AccountConsole' || clientName == 'StructureCMSLms'){
        delete productDetailsMap['StructureCMS'];
      }
      const filteredProducts = Object.keys(productDetailsMap)
      .filter(product => product !== clientName)
      .map(product => productDetailsMap[product]);

        const randomIndex = Math.floor(Math.random() * filteredProducts.length);
        setRandomProductDetails(filteredProducts[randomIndex]);
    };

    var givenRedirectUrl = localStorage.getItem('givenRedirectUrl');
    var mrsClientid = localStorage.getItem('mrsClientid');
    var lmsSiteId = localStorage.getItem('lmsSiteId');
    if(mrsClientid && givenRedirectUrl){
      const generateAuthCode = async () => {
        console.log("setSuccessRedirectionUrl before is ", successRedirectionUrl);
        const apiUrl = import.meta.env.VITE_STORE_TOKEN_LAMBDA_URL + 'register-authenticator/generate-auth';
        const bearerToken = tokens?.idToken;
        var username = tokensParsed?.accessToken.username;
        const requestBody = {
          clientId: mrsClientid,
          redirectUrl: givenRedirectUrl,
        };

        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${bearerToken}`,
            },
            body: JSON.stringify(requestBody),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          var deviceId = localStorage.getItem('deviceId');
          if(deviceId == null){
            deviceId = uuidv4();
            console.log('DeviceId is:', deviceId);
            localStorage.setItem('deviceId', deviceId);
          }
          setApiResponse(data);
          console.log('Auth Code:', data);
          console.log('Auth Code body is:', data?.body);
          console.log('Auth Code body inside authcode:', data?.body?.authCode);
          var authCode = data?.body?.authCode
          var registerValue = "PasswordlessRegistration";
          const queryParams = new URLSearchParams(location.search);
          const invite_encoded_token = queryParams.get('invite_encoded_token');
          // var registerValue = "passwordless_auth";
          // var username = tokensParsed?.accessToken.username;
          successRedirectionUrl = givenRedirectUrl + "?authCode=" + authCode + "&userName=" + username + "&lmsSiteId=" + lmsSiteId+ "&deviceId=" + deviceId +"&invite_token=" + invite_encoded_token;
          if (signInStatus && signInStatus === "SIGNED_IN" && tokens && (registerValue !== "passwordless_auth")){
            localStorage.removeItem('givenRedirectUrl');
            localStorage.removeItem('mrsClientid');
            window.location.href = successRedirectionUrl;
          }
          console.log("setSuccessRedirectionUrl is ", successRedirectionUrl);
        } catch (error) {
          if (error instanceof Error) {
          console.error('Error:', error.message);
          }
        }
      };

      generateAuthCode();
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className="conatiner main-container">
      <div className="row" style={{height: 'inherit'}}>
        <div className="col-md-7 flex-container">
          {randomProductDetails && (
            <><div className="background e-deployer">
              <div className="container" style={{ paddingTop: 'inherit' }}>
                <div className="animatedE">
                  <div className="box3" id="e-background"></div>
                  <div className="box2" id="pattern-rotate">
                    <img src={randomProductDetails.backGroundImage} id="pat" />
                  </div>
                </div>
              </div>

            </div><section className="first bg-white">
                <div className="container pb-6">
                  <div className="row main-card">
                    <div className="col-sm-1"> </div>
                    <div className="col-sm-12 col-md-5">
                      <img src={randomProductDetails.mainImage} className="img-fluid"/>
                    </div>
                    <div className="col-sm-12 col-md-5 d-flex flex-column align-content-between">
                      <div className="row">
                        <div className="col ">
                          <img className="img100" src={randomProductDetails.mainLogo} />
                          <h1>
                            {randomProductDetails.mainHeading}
                          </h1>
                          <p>
                            {randomProductDetails.mainSubText}
                          </p>
                        </div>
                      </div>
                      <div className="row align-items-end mt-auto">
                        {/* <div className="col-sm mb-2"> <a className="btn btn-danger btn-validate-plus btn-outline btn-block">
                          {randomProductDetails.mainBtnLink}
                        </a> </div> */}
                        <div className="col-sm mb-2"> <a href={randomProductDetails.mainBtnLink} target="_blank" className="btn btn-signout btn-block">
                          {randomProductDetails.mainBTN}
                        </a> </div>
                      </div>
                      <div className="row">
                        <p className="small">
                          * Note: Clicking this button wouldn't effect your current redirection.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section></>
          )}

        </div>

        <div className="col-md-5 flex-container" style={{background:'#e8e8fd'}}>
          <div className="passwordless-card-container">
            <img
              src="https://mr.cdn.ignitecdn.com/client_assets/acccount_structure/media/themes/img/mr-signin.svg"
              className="passwordless-customer-logo"
            />
            {clientDetails && (
              <div className="passwordless-text-center passwordless-customer-name">
                <img
                src={clientDetails.cardLogo}
                className="passwordless-customer-logo"
                />
              </div>
            )}
            {clientDetails && (
              <>
                <div style={{display:'block'}}>
                  <div className="passwordless-loading-spinner" style={{marginRight: '20px'}} />
                  Please wait you will be redirected to {clientDetails.cardName}....
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientRedirectionHandler;
