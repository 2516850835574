import "./App.css";
// import { usePasswordless } from "amazon-cognito-passwordless-auth/react";
import StepUpAuth from "./StepUpAuth";
import { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Header from "./Header";
import "./dashboard.css"
import { usePasswordless } from "../amazon-cognito-passwordless-auth/react/hooks";
import Authenticators from "../amazon-cognito-passwordless-auth/react/Authenticators";
import  StickeyBottom from "../amazon-cognito-passwordless-auth/react/StickeyBottom";
import Support from "../amazon-cognito-passwordless-auth/react/Support"
import Footer from "./Footer";
function App() {
  const {
    signOut,
    globalSignOut,
    signInStatus,
    tokens,
    tokensParsed,
  } = usePasswordless();

  const [apiResponse, setApiResponse] = useState(null);
  const [showSpinnerModal, setShowSpinnerModal] = useState(false);
  var mrsClientid: any, givenRedirectUrl: any;
  // const [successRedirection, setSuccessRedirection] = useState(false);
  var successRedirectionUrl = "";

  // const [successRedirectionUrl, setSuccessRedirectionUrl] = useState("");

  // useEffect(() => {
  //   const getQueryParam = (param: string) => {
  //     const queryString = window.location.search;
  //     const urlParams = new URLSearchParams(queryString);
  //     return urlParams.get(param);
  //   };

  //   mrsClientid = getQueryParam('mrsclientid');
  //   givenRedirectUrl = getQueryParam('redirectUrl');
  //   // registerValue = getQueryParam('register');
  //   // console.log('Value of register:', registerValue);
  //   // givenRedirUrl = getQueryParam('redirectUrl');
  //   // console.log('Value of givenRedirUrl:', givenRedirUrl);
  // }, []);

  const [showStepUpAuth, setShowStepUpAuth] = useState(false);
  if (showStepUpAuth && signInStatus !== "SIGNED_IN") setShowStepUpAuth(false);

  // Function to make API call for generating auth code
  // const generateAuthCode = async () => {
  //   console.log("setSuccessRedirectionUrl before is ", successRedirectionUrl);
  //   // console.log("setSuccessRedirectionUrl before is ", thissuccessRedirectionUrl);
  //   const apiUrl = 'https://vg0ys3lcs2.execute-api.us-east-1.amazonaws.com/v1/register-authenticator/generate-auth';
  //   const bearerToken = tokens?.idToken;

  //   const requestBody = {
  //     clientId: "821a91d9870bcb9cadbc226d72b3cfe9",
  //     redirectUrl: "http://localhost:3001/users/passwordless_login",
  //   };

  //   try {
  //     const response = await fetch(apiUrl, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${bearerToken}`,
  //       },
  //       body: JSON.stringify(requestBody),
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const data = await response.json();
  //     // const data = {body: "34565342546565456"}
  //     console.log('Auth Code:', data);
  //     console.log('Auth Code body is:', data?.body);
  //     console.log('Auth Code body inside authcode:', data?.body?.authCode);
  //     var authCode = data?.body?.authCode
  //     var givenRedirectUrl = localStorage.getItem('givenRedirectUrl');
  //     givenRedirectUrl = "http://172.16.21.27:3001/users/passwordless_login"
  //     var mrsClientid = localStorage.getItem('mrsClientid');
  //     console.log("givenRedirectUrl is ", givenRedirectUrl);

  //     var registerValue = "login";
  //     var username = tokensParsed?.accessToken.username;
  //     successRedirectionUrl = givenRedirectUrl + "?authCode=" + authCode + "&userName=" + username;
  //     // setSuccessRedirectionUrl("http://172.16.21.27:3001/users/passwordless_login",() => {
  //     //   console.log("test", successRedirectionUrl);
  //     // });
  //     console.log("setSuccessRedirectionUrl is ", successRedirectionUrl);
  //     // setSuccessRedirection(true)
  //   } catch (error) {
  //     console.error('Error:', error.message);
  //   }
  // };




  // if (signInStatus && signInStatus === "SIGNED_IN" && tokens){
  //   var authCode = generateAuthCode();
  //   // console.log("genAuthCode is ",genAuthCode);
  //   console.log("authcode is ", authCode);
    
  //   var registerValue = "login"
    
  //   useEffect(()=> {
  //     if (successRedirectionUrl && signInStatus === "SIGNED_IN" && (registerValue !== "passwordless_auth")){
  //       window.location.href = successRedirectionUrl;
  //     }
  //   }, [successRedirectionUrl]);
  // }



  // useEffect(()=> {

  //   const getQueryParam = (param: string) => {
  //     const queryString = window.location.search;
  //     const urlParams = new URLSearchParams(queryString);
  //     return urlParams.get(param);
  //   };

  //   var givenRedirectUrl = localStorage.getItem('givenRedirectUrl');
  //   var mrsClientid = localStorage.getItem('mrsClientid');

  //   var clientSignOut = getQueryParam('client_sign_out');
  //   console.log("clientSignOut is :",clientSignOut);
  //   if(clientSignOut && clientSignOut === "true"){
  //     newSignOut();
  //   }else if(mrsClientid && givenRedirectUrl){
  //     const generateAuthCode = async () => {
  //       setShowSpinnerModal(true);
  //       console.log("setSuccessRedirectionUrl before is ", successRedirectionUrl);
  //       // console.log("setSuccessRedirectionUrl before is ", thissuccessRedirectionUrl);
  //       const apiUrl = 'https://vg0ys3lcs2.execute-api.us-east-1.amazonaws.com/v1/register-authenticator/generate-auth';
  //       // const apiUrl = 'https://jtdx2z0cuj.execute-api.us-east-1.amazonaws.com/v1/register-authenticator/generate-auth';
  //       const bearerToken = tokens?.idToken;
  //       var username = tokensParsed?.accessToken.username;
  //       const requestBody = {
  //         clientId: mrsClientid,
  //         redirectUrl: givenRedirectUrl,
  //         userName: username,
  //       };
    
  //       try {
  //         const response = await fetch(apiUrl, {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': `Bearer ${bearerToken}`,
  //           },
  //           body: JSON.stringify(requestBody),
  //         });
    
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok');
  //         }
    
  //         const data = await response.json();
  //         setApiResponse(data);
  //         // const data = {body: "34565342546565456"}
  //         console.log('Auth Code:', data);
  //         console.log('Auth Code body is:', data?.body);
  //         console.log('Auth Code body inside authcode:', data?.body?.authCode);
  //         var authCode = data?.body?.authCode
          
  //         // givenRedirectUrl = "http://172.16.21.27:3001/users/passwordless_login"
    
  //         // var registerValue = "PasswordlessRegistration";
  //         var registerValue = "passwordless_auth";
  //         // var username = tokensParsed?.accessToken.username;
  //         successRedirectionUrl = givenRedirectUrl + "?authCode=" + authCode + "&userName=" + username;
  
  //         if (signInStatus && signInStatus === "SIGNED_IN" && tokens && (registerValue !== "passwordless_auth")){
  //           localStorage.removeItem('givenRedirectUrl');
  //           localStorage.removeItem('mrsClientid');
  //           window.location.href = successRedirectionUrl;

  //         }
  //         // setSuccessRedirectionUrl("http://172.16.21.27:3001/users/passwordless_login",() => {
  //         //   console.log("test", successRedirectionUrl);
  //         // });
  //         console.log("setSuccessRedirectionUrl is ", successRedirectionUrl);
  //         // setSuccessRedirection(true)
  //       } catch (error) {
  //         if (error instanceof Error) {
  //         console.error('Error:', error.message);
  //         }
  //       }
  //     };

  //     generateAuthCode();
  //   }

    
  // }, []);
  
  
  return (
    // <div className="app">
    //   <div>This YOUR app</div>
    //   <div>Hi there {tokensParsed?.idToken.email}</div>
    //   <button
    //     onClick={() => {
    //       newSignOut();
    //     }}
    //   >
    //     Sign out
    //   </button>
    //   <button
    //     onClick={() => toggleShowAuthenticatorManager()}
    //     disabled={showAuthenticatorManager}
    //   >
    //     Manage authenticators
    //   </button>
    //   {/* <button onClick={generateAuthCode}>
    //     Generate Auth Code
    //   </button> */}
    //   {showStepUpAuth ? (
    //     <StepUpAuth />
    //   ) : (
    //     <button onClick={() => setShowStepUpAuth(true)}>
    //       Show Step Up Auth
    //     </button>
    //   )}
    // </div>
    <div className="d-flex flex-column">
      <Header/>
      {/* <div className="theBackground e-deployer">
        <div className="container">
          <div className="animatedE">
            <div className="box" id="e-background"></div>
            <div className="box2" id="pattern-rotate">
              <img src="https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/themes/images/mrsignin-bg.svg?1648142412" id="pat"/> 
            </div>
          </div>
        </div>
      </div>

      <section className="first bg-white">
        <div className="container pb-6">
          <div className="row main-card">
            <div className="col-sm-1"> </div>
            <div className="col-sm-12 col-md-5"> 
                <img src="https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/6242/1f38/287c/d737/0949/32b5/original_mrsignin_herophot.jpg?1648500535" className="img-fluid"/> 
            </div>
            <div className="col-sm-12 col-md-5 d-flex flex-column align-content-between"> 
              <div className="row">
                <div className="col ">
                <img src="https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/623a/0711/a386/a07f/4732/ed10/original_mr-signin_logo.svg?1647970065"/>
                <h1>
                  Welcome to the power of the digital identity network.
                </h1>
                <p>
                  Mr Sign In is Market Rithm's Single Sign On (SSO) application designed to unify user credentials across all Market Rithm applications.
                </p>
                </div>
              </div>
              <div className="row align-items-end mt-auto">
                <div className="col-sm mb-2">
                  <Link to='/authenticators' className="btn btn-validate-plus btn-outline btn-block">Manage authenticators</Link></div>
                <div className="col-sm mb-2"> <a onClick={() => {newSignOut();}} className="btn btn-signout btn-block">
                  Sign Out
                  </a> </div>
                  <div className="col-sm mb-2" style={{ display: 'none' }}> <a onClick={() => {onlyDeviceSignOut();}} className="btn btn-signout btn-block">
                  Device Sign Out
                  </a> </div>
                  <div className="col-sm mb-2"> <a onClick={() => {newGlobalSignOut();}} className="btn btn-signout btn-block">
                  Sign Out from all Devices & Apps
                  </a> </div>
              </div>             
            </div>
          </div>
        </div>
      </section>
      <div className="modal fade" id="setUpAuthModal" aria-labelledby="setUpAuthModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <StepUpAuth />
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>
      {(showSpinnerModal) && (
        <div className="modal fade show" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body inline-properties flex-container">
                  <div className="passwordless-loading-spinner" />
                  <div>Please wait you will be redirected ....</div>
              </div>
            </div>
          </div>
      </div>
      ) } */}
      <Authenticators/>

      <StickeyBottom/>

      <Support/>

      <Footer/>
  </div>
  );
}

export default App;